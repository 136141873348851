<template>
    <div>
        <LoadingSpinner v-if="loading" style="height: 500px;" />
        <LoadingError v-else-if="loadingError" style="height: 500px;" />
        <div v-else-if="Object.keys(order).length">
            <div class="card mb-3">
                <div class="bg-holder d-none d-lg-block bg-card" style="pointer-events: none;" />
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm">
                            <div class="d-flex flex-row align-items-center">
                                <h5 class="m-0">Order #{{ order.bigcommerce_order_id }}</h5>
                                <BCOrderStatusBadge v-if="order.status_name" :status="order.status_name" class="ml-2" />
                            </div>
                            <span class="fs--1">{{ order.created | formatISODate('MMM d, YYY') }}</span>
                        </div>
                        <div class="col-auto d-flex align-items-center">
                            <button class="btn btn-sm btn-falcon-success mr-3"
                                    v-if="order.status_id === 12 && $auth.hasPermissions('verify:orders')"
                                    :disabled="verifying"
                                    @click="verifyOrder">
                                <i class="fa fa-check mr-1" />
                                Verify Order
                            </button>
                            <DocumentComboButton :doc-url="`/orders/${this.order.id}/pick_ticket/`"
                                                 :file-name="`pick_ticket_${this.order.bigcommerce_order_id}.pdf`"
                                                 paper-size="LETTER"
                                                 v-if="$auth.hasPermissions('read:orders.pick_ticket', 'print:orders.pick_ticket')">
                                Pick Ticket
                            </DocumentComboButton>
                        </div>
                    </div>
                </div>
            </div>
            <!-- General Information -->
            <div class="card mb-3" v-if="order.bc_data">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-6" v-if="order.shipping_address">
                            <h5 class="mb-3 fs-0">Shipping Address</h5>
                            <h6 class="mb-2">{{ order.shipping_address.company_name }}</h6>
                            <p class="mb-1 fs--1">
                                {{ order.shipping_address.address_line_1 }}<br>
                                <template v-if="order.shipping_address.address_line_2">
                                    {{ order.shipping_address.address_line_2 }}<br>
                                </template>
                                {{ order.shipping_address.city }},
                                {{ order.shipping_address.state_province_code }}
                                {{ order.shipping_address.postal_code }}
                                {{ order.shipping_address.country_iso_code }}
                            </p>
                            <p class="mb-0 fs--1">
                                <strong>Phone: </strong>
                                <a v-if="order.shipping_address.phone" :href="`tel:${order.shipping_address.phone}`">
                                    {{ order.shipping_address.phone }}
                                </a>
                                <span v-else class="text-secondary">N/A</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Products -->
            <div v-if="order && order.bc_data && order.bc_data.products" class="card mb-3">
                <div class="card-body">
                    <div class="table-responsive fs--1">
                        <table class="table table-striped border-bottom">
                            <thead class="bg-200 text-900">
                                <tr>
                                    <th class="border-0">Products</th>
                                    <th class="border-0 text-center">Quantity</th>
                                    <th class="border-0 text-right">Rate</th>
                                    <th class="border-0 text-right">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="product in order.bc_data.products" :key="product.id">
                                    <td class="align-middle">
                                        <h6 class="mb-0 text-nowrap">{{ product.name }}</h6>
                                    </td>
                                    <td class="align-middle text-center">{{ product.quantity }}</td>
                                    <td class="align-middle text-right">{{ product.base_price | formatDollars }}</td>
                                    <td class="align-middle text-right">{{ product.base_total | formatDollars }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row no-gutters justify-content-end">
                        <div class="col-auto">
                            <table class="table table-sm table-borderless fs--1 text-right">
                                <tbody>
                                    <tr>
                                        <th class="text-900">Subtotal:</th>
                                        <td class="font-weight-semi-bold">
                                            {{ order.bc_data.subtotal_ex_tax | formatDollars }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="text-900">Tax:</th>
                                        <td class="font-weight-semi-bold">
                                            {{ order.bc_data.total_tax | formatDollars }}
                                        </td>
                                    </tr>
                                    <tr class="border-top">
                                        <th class="text-900">Total:</th>
                                        <td class="font-weight-semi-bold">
                                            {{ order.bc_data.total_inc_tax | formatDollars }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Shipments -->
            <div class="card mb-3" v-if="$auth.hasPermissions('read:shipments')">
                <div class="card-body">
                    <div class="table-responsive fs--1">
                        <table class="table table-striped border-bottom">
                            <thead class="bg-200 text-900">
                                <tr>
                                    <th class="border-0 align-middle">Shipments</th>
                                    <th class="border-0 align-middle d-flex text-nowrap">
                                        <router-link :to="{ name: 'create-shipment', query: { 'order-id': $route.params.id }, params: { _order: order }}"
                                                     class="btn btn-sm btn-falcon-primary ml-auto"
                                                     v-if="$auth.hasPermissions('create:shipments')">
                                            <span class="mr-1">Create Shipment</span>
                                            <span class="fas fa-arrow-right" />
                                        </router-link>
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-if="order.shipments.length">
                                <tr v-for="shipment in order.shipments" :key="shipment.id">
                                    <td class="align-middle d-flex flex-column text-nowrap">
                                        <h6 class="mb-1">
                                            <router-link :to="{ name: 'shipment-details', params: { id: shipment.id }}">
                                                {{ shipment.created | formatISODate('MMM d, YYY') }}{{ shipment.carrier ? `, via ${shipment.carrier.name}` : '' }}
                                            </router-link>
                                        </h6>
                                        <span>Tracking Number:
                                            <a v-if="shipment.tracking_number && shipment.tracking_url"
                                               class="mb-0"
                                               :href="shipment.tracking_url"
                                               target="_blank">
                                                {{ shipment.tracking_number }}
                                            </a>
                                            <span v-else-if="shipment.tracking_number">
                                                {{ shipment.tracking_number }}
                                            </span>
                                            <span v-else>N/A</span>
                                        </span>
                                    </td>
                                    <td class="align-middle text-right">
                                        <DocumentComboButton :doc-url="`/shipments/${shipment.id}/packing_list/`"
                                                             :file-name="`packing_list_${shipment.identifier}.pdf`"
                                                             paper-size="LETTER"
                                                             class="my-1"
                                                             v-if="$auth.hasPermissions('read:shipments.packing_list', 'print:shipments.packing_list')">
                                            Packing List
                                        </DocumentComboButton>
                                        <DocumentComboButton v-if="shipment.label_url && $auth.hasPermissions('read:shipments.shipping_label', 'print:shipments.shipping_label')"
                                                             :doc-url="`/shipments/${shipment.id}/shipping_label/`"
                                                             :file-name="`shipping_label_${shipment.identifier}.pdf`"
                                                             paper-size="LETTER"
                                                             class="my-1 ml-2">
                                            Shipping Label
                                        </DocumentComboButton>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td>
                                        Once shipments are created for this order, they will appear in this section
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getOrder, verifyOrder } from '@/services/OrderService';
    import BCOrderStatusBadge from '../components/BCOrderStatusBadge';
    import DocumentComboButton from '../components/DocumentComboButton';
    import LoadingError from '../components/LoadingError';
    import LoadingSpinner from '../components/LoadingSpinner';

    export default {
        name: 'OrderDetails',
        components: {
            BCOrderStatusBadge,
            LoadingSpinner,
            LoadingError,
            DocumentComboButton,
        },
        data() {
            return {
                loading: true,
                loadingError: false,
                verifying: false,
                order: {},
            };
        },
        computed: {
            baseUrl() {
                return process.env.VUE_APP_API_BASE;
            },
        },
        mounted() {
            this.getOrder();
        },
        methods: {
            async getOrder() {
                this.loading = true;
                try {
                    const response = await getOrder(this.$route.params.id);
                    this.order = response.data;
                    this.loadingError = false;
                } catch (error) {
                    if (error.response?.status === 404) {
                        this.$router.push({ name: '404' });
                    }
                    this.loadingError = true;
                    this.$alerts.danger('An error occurred',
                                        'This page failed to load. Please contact support if this problem persists.');
                } finally {
                    this.loading = false;
                }
            },
            async verifyOrder() {
                this.verifying = true;
                try {
                    const { data: order } = await verifyOrder(this.order.id);
                    this.order.status_id = order.order_status_id;
                    this.order.status_name = order.order_status_name;
                } catch (error) {
                    this.$alerts.danger('An error occurred',
                                        'Could not verify order. Please contact support if this problem persists.');
                } finally {
                    this.verifying = false;
                }
            },
        },
    };
</script>

<style scoped>
    .bg-card {
        background-image: url('/theme/img/corner-4.png');
        opacity: 0.7;
    }

    /* Revert this stupid override in Falcon */
    td >>> .dropdown-toggle::after {
        display: inline-block;
    }

</style>
